.bg-moved-down {
  object-position: 0 5px;
}

.bg-pic-profile {
  object-fit: none;
  object-position: -20px 0px;
}

#sidebar-menu > ul > li > a i {
  width: 25px;
  font-size: 1.3rem;
}

.w-35px {
  width: 35px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, transparent 30%, black 30%);
  z-index: 10;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.w-35px {
  width: 35px;
}

.object-fit-cover {
  object-fit: cover;
}
