body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dashboard-icon {
  width: 60px;
  height: 60px;
}

.dashboard-icon-shadow{
  box-shadow: 4px 4px 15px 4px rgb(64 69 75 / 22%)
}

.font-35 {
  font-size: 35px !important;
}
